import React from 'react'

import { Column, Container, Row } from '@/components/base/gridview'
import Image from '@/components/base/image/Image'

import { MobileAppProps } from './type'
import Anchor from '@/components/base/anchor/Anchor'

const MobileApp: React.FunctionComponent<MobileAppProps> = ({
  title,
  desc,
  image,
  platforms,
}) => {
  return (
    <div className="mobile-app section">
      <Container size="medium">
        <Row xs={{ align: 'center' }}>
          <Column
            xs={{ size: 12 }}
            sm={{ size: 12 }}
            md={{ size: 5 }}
            lg={{ size: 5 }}
            xl={{ size: 5 }}
            className="text-area"
          >
            <div className="title">{title}</div>
            {desc && <div dangerouslySetInnerHTML={{ __html: desc }} />}
            <div className="platforms">
              {platforms?.map((item, index) => (
                <div key={index} className="item">
                  <figure>
                    <Anchor {...item.link}>
                      <Image {...item.image} alt={item.title} />
                    </Anchor>
                  </figure>
                </div>
              ))}
            </div>
          </Column>
          <Column
            xs={{ size: 12 }}
            sm={{ size: 12 }}
            md={{ size: 7 }}
            lg={{ size: 7 }}
            xl={{ size: 7 }}
          >
            <Image {...image} alt={title} />
          </Column>
        </Row>
      </Container>
    </div>
  )
}

export default MobileApp
